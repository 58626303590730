<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        Perform each of the required pressure unit conversions to fill in the table below.
      </p>

      <table style="max-width: 650px">
        <thead class="thead text-center br">
          <th style="vertical-align: middle; height: 50px" />
          <th style="vertical-align: middle; height: 50px">
            <stemble-latex content="$\text{Pa}$" />
          </th>
          <th style="vertical-align: middle; height: 50px">
            <stemble-latex content="$\text{kPa}$" />
          </th>
          <th style="vertical-align: middle; height: 50px">
            <stemble-latex content="$\text{atm}$" />
          </th>
          <th style="vertical-align: middle; height: 50px">
            <stemble-latex content="$\text{Torr}$" />
          </th>
          <th style="vertical-align: middle; height: 50px">
            <stemble-latex content="$\text{bar}$" />
          </th>
        </thead>

        <tbody>
          <tr>
            <td style="height: 15px">
              <stemble-latex content="$\text{a)}$" />
            </td>
            <td>
              <number-value :value="pa1" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.kpa1"
                group-size="sm"
                class="centered-input ma-1"
                :show-scientific-notation="false"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.atm1"
                group-size="sm"
                class="centered-input ma-1"
                :show-scientific-notation="false"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.torr1"
                group-size="sm"
                class="centered-input ma-1"
                :show-scientific-notation="false"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.bar1"
                group-size="sm"
                class="centered-input ma-1"
                :show-scientific-notation="false"
              />
            </td>
          </tr>
          <tr>
            <td style="height: 15px">
              <stemble-latex content="$\text{b)}$" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.pa2"
                group-size="sm"
                class="centered-input ma-1"
                :show-scientific-notation="false"
              />
            </td>
            <td>
              <number-value :value="kpa2" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.atm2"
                group-size="sm"
                class="centered-input ma-1"
                :show-scientific-notation="false"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.torr2"
                group-size="sm"
                class="centered-input ma-1"
                :show-scientific-notation="false"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.bar2"
                group-size="sm"
                class="centered-input ma-1"
                :show-scientific-notation="false"
              />
            </td>
          </tr>
          <tr>
            <td style="height: 15px">
              <stemble-latex content="$\text{c)}$" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.pa3"
                group-size="sm"
                class="centered-input ma-1"
                :show-scientific-notation="false"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.kpa3"
                group-size="sm"
                class="centered-input ma-1"
                :show-scientific-notation="false"
              />
            </td>
            <td>
              <number-value :value="atm3" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.torr3"
                group-size="sm"
                class="centered-input ma-1"
                :show-scientific-notation="false"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.bar3"
                group-size="sm"
                class="centered-input ma-1"
                :show-scientific-notation="false"
              />
            </td>
          </tr>
          <tr>
            <td style="height: 15px">
              <stemble-latex content="$\text{d)}$" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.pa4"
                group-size="sm"
                class="centered-input ma-1"
                :show-scientific-notation="false"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.kpa4"
                group-size="sm"
                class="centered-input ma-1"
                :show-scientific-notation="false"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.atm4"
                group-size="sm"
                class="centered-input ma-1"
                :show-scientific-notation="false"
              />
            </td>
            <td>
              <number-value :value="torr4" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.bar4"
                group-size="sm"
                class="centered-input ma-1"
                :show-scientific-notation="false"
              />
            </td>
          </tr>
          <tr>
            <td style="height: 15px">
              <stemble-latex content="$\text{e)}$" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.pa5"
                group-size="sm"
                class="centered-input ma-1"
                :show-scientific-notation="false"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.kpa5"
                group-size="sm"
                class="centered-input ma-1"
                :show-scientific-notation="false"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.atm5"
                group-size="sm"
                class="centered-input ma-1"
                :show-scientific-notation="false"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.torr5"
                group-size="sm"
                class="centered-input ma-1"
                :show-scientific-notation="false"
              />
            </td>
            <td>
              <number-value :value="bar5" />
            </td>
          </tr>
        </tbody>
      </table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '../NumberValue';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question394',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        pa2: null,
        pa3: null,
        pa4: null,
        pa5: null,
        kpa1: null,
        kpa3: null,
        kpa4: null,
        kpa5: null,
        atm1: null,
        atm2: null,
        atm4: null,
        atm5: null,
        torr1: null,
        torr2: null,
        torr3: null,
        torr5: null,
        bar1: null,
        bar2: null,
        bar3: null,
        bar4: null,
      },
    };
  },
  computed: {
    pa1() {
      return this.taskState.getValueBySymbol('pa1').content;
    },
    kpa2() {
      return this.taskState.getValueBySymbol('kpa2').content;
    },
    atm3() {
      return this.taskState.getValueBySymbol('atm3').content;
    },
    torr4() {
      return this.taskState.getValueBySymbol('torr4').content;
    },
    bar5() {
      return this.taskState.getValueBySymbol('bar5').content;
    },
  },
};
</script>
<style scoped>
td {
  vertical-align: center;
  text-align: center;
}
</style>
